/*
Runda Normal
font-family: runda, sans-serif;
font-weight: 400;
font-style: normal;

Runda Medium
font-family: runda, sans-serif;
font-weight: 500;
font-style: normal;

Runda Bold
font-family: runda, sans-serif;
font-weight: 700;
font-style: normal;

Runda Black
font-family: runda, sans-serif;
font-weight: 900;
font-style: normal;
*/
/* Box sizing rules */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0; }

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

/* Set core body defaults */
body {
  min-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none; }

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto; }

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block; }

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit; }

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    -webkit-transition-duration: 0.01ms !important;
         -o-transition-duration: 0.01ms !important;
            transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

a {
  text-decoration: none;
  color: inherit; }

.clearfix:before,
.clearfix:after {
  content: '';
  display: table; }

.clearfix:after {
  clear: both; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }
  .embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

html {
  font-size: 10px;
  height: 100%; }

.page {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: hidden;
  position: fixed;
  top: -1px; }
  .page__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background: -webkit-gradient(linear, right top, left top, from(#add1f0), color-stop(#b8cbea), color-stop(#b4ecdc), color-stop(#eee6b9), to(#f3c0de));
    background: -o-linear-gradient(right, #add1f0, #b8cbea, #b4ecdc, #eee6b9, #f3c0de);
    background: linear-gradient(270deg, #add1f0, #b8cbea, #b4ecdc, #eee6b9, #f3c0de);
    background-size: 1000% 1000%;
    -webkit-animation: AnimationName 11s ease infinite;
    animation: AnimationName 11s ease infinite;
    -webkit-filter: saturate(1.4);
            filter: saturate(1.4); }

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes AnimationName {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }
    @media only screen and (max-width: 549px) {
      .page__background {
        height: 140%;
        width: 120%; } }
    .page__background canvas {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%);
      border: 1px solid red; }
  .page__logo {
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    width: 100%;
    text-align: center;
    padding: 0 2rem; }
    .page__logo svg {
      width: 310px;
      height: auto; }
      @media only screen and (max-width: 549px) {
        .page__logo svg {
          height: auto; } }
  .page__contact {
    position: relative;
    z-index: 2;
    width: 100%;
    text-align: center;
    margin-top: auto;
    margin-bottom: 0;
    -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
            align-self: flex-end;
    padding: 0 2rem; }
    @media only screen and (max-width: 549px) {
      .page__contact {
        margin-bottom: 5%; } }
    .page__contact p {
      font-family: runda, sans-serif;
      font-weight: 400;
      font-style: normal;
      color: white;
      font-size: 1.3rem;
      line-height: 1.5; }
      @media only screen and (max-width: 549px) {
        .page__contact p {
          font-size: 1.6rem;
          line-height: 1.4;
          max-width: 300px;
          margin: 0 auto; } }
      .page__contact p a {
        -webkit-transition: all 111ms cubic-bezier(0.39, 0.575, 0.565, 1);
        -o-transition: all 111ms cubic-bezier(0.39, 0.575, 0.565, 1);
        transition: all 111ms cubic-bezier(0.39, 0.575, 0.565, 1); }
        .page__contact p a:hover {
          color: #d0ab4a; }
    .page__contact .divider {
      width: 4.8rem;
      height: 0.3rem;
      margin: 4rem auto;
      background: #d0ab4a;
      display: block; }

#parallax {
  -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
          transform: translateY(20px);
  opacity: 0;
  -webkit-transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 1.5s cubic-bezier(0.39, 0.575, 0.565, 1); }
  #parallax.active {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    opacity: 1; }

.terms {
  position: fixed;
  top: 5rem;
  bottom: 5rem;
  width: 90vh;
  margin: 0 auto;
  max-width: 70rem;
  padding: 5rem;
  z-index: 22;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  overflow: auto;
  font-family: Helvetica, Arial, sans-serif; }
  .terms::-webkit-scrollbar-track {
    background-color: #f6dfb4; }
  .terms::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    background-color: #f6dfb4; }
  .terms::-webkit-scrollbar-thumb {
    background-color: black;
    background-color: #ffc3da;
    border: 0; }
  .terms * {
    color: white; }
  .terms h2,
  .terms h3 {
    font-weight: 400;
    margin-bottom: 20px; }
  .terms ol {
    padding-left: 15px;
    list-style-type: decimal !important; }
    .terms ol li {
      padding-left: 15px;
      display: list-item;
      padding-bottom: 0;
      display: block;
      padding-left: 20px;
      position: relative;
      font-weight: 200;
      line-height: 1.8; }
      .terms ol li:before {
        position: absolute;
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background: white;
        left: 0;
        top: 10px; }
  .terms ul li {
    padding-left: 10px;
    display: list-item; }
    .terms ul li:before {
      display: none; }
  .terms ol > li > p > span {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 3rem; }
  .terms h1 {
    font-size: 2rem;
    text-transform: uppercase; }
